export const questions = [
    {
        title: "Can I redeem my FD before the original term?"
    },
    {
        title: "How do I pay my Credit card bill?"
    },
    {
        title: "How stay safe in an area with pollution"
    }
]